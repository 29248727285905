.username{
    margin-top: 24px;
    color:$textGrey;
    font-size: 14px;
}
.venmo{
    display: inline-block;
    margin: 12px;
}
.paypal{
    display: inline-block;
    margin: 12px;
}
.cashapp{
    display:inline-block;
    margin: 12px;
    
}
.venmo-button{
    padding: 20px 40px;
    font-size: 16px;
    font-weight: 900;
    border-radius: 4px;
    color: white;
    text-decoration: none;
    background-color:$venmoBlue;
    max-width: 185px;
    &:hover{
        opacity: 0.7;
        text-decoration: underline; 
    }
}
.paypal-button{
    padding: 20px 43px;
    font-size: 16px;
    font-weight: 900;
    border-radius: 4px;
    color: white;
    text-decoration: none;
    background-color: $paypalBlue;
    max-width: 190px;
    &:hover{
        opacity: 0.7;
        text-decoration: underline; 
    }
}
.cashapp-button{
    padding: 20px 31px;
    font-size: 16px;
    font-weight: 900;
    border-radius: 4px;
    color: white;
    text-decoration: none;
    background-color: $cashApp;
    max-width: 190px;
    &:hover{
        opacity: 0.7;
        text-decoration: underline; 
    }
}