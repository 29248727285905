$grey: #F6F6F6;
$transparentGrey:#F6F6F6D9;
$white: #FFFFFF;
$orange: #F89D1C;
$venmoBlue: #3181C3;
$cashApp: #1CD13A;
$paypalBlue: #00457C;
$footerBackground: #191D23;
$border: #D8D8D8;
$textGrey:#768489;
$textLightGrey: #B9B9B9;
$textBlack: #191D23;
$linkGold: #FBAC21;
$Shadow: #0000001A;
$interFont: 'Inter', sans-serif;