h1,h2,h3,p,a{
    font-family: $interFont;
}

h1{
    font-weight: 900;
}

.wrapper{
    width: 96%;
    max-width: 600px;
    margin:auto;
}
.container{
    background-color: $white;
}
body{
    margin:0;
}

.intro-text{
    text-align: center;
    margin-top:80px;
    bold{
        font-weight: 900;
    }
    h1{
        margin-bottom: 0;
    }
    p{
        margin-top:0;
        margin-bottom: 24px;
    }
}

.add-link{
    padding: 10px 20px;
    border: solid;
    border-radius: 4px;
    border-width: 1px;
    border-color: $footerBackground;
    text-decoration: none;
    font-size: 14px;
    color:$footerBackground;
    &:after{
        content:"   +";
        color: $linkGold;
        text-decoration: none;
    }
    &:hover{
        text-decoration: underline;
    }
}

.randomEployee-box{
    text-align: center;
    max-width: 480px;
    margin: auto;
    padding-bottom: 10px;
    padding-top:10px;
    margin-top: 72px;
    margin-bottom:10px;
    box-shadow: 0px 0px 104px 0px $Shadow;
    button{ 
        display: block;
        margin: auto;  
        font-size: 14px;
        padding: 10px 15px;
        text-decoration: none;
        background-color: transparent;
        margin-top: 16px;
        margin-bottom: 32px;
        text-transform: uppercase;
        border-radius: 4px;
        border-color:#000;
        &:after{
            content: "   \2192";
            font-size: 14px;
            color: $linkGold;

        }
        &:hover{
            text-decoration: underline;
            cursor: pointer;
        }
        
    }
}
.random-worker{
    text-align: center;
    background-color: $white;
    box-shadow:#000;
    max-width: 480px;
    margin: auto;
    .random-header{
        font-size: 12px;
        color: $linkGold;
        text-transform: uppercase;
    }

}
.venmo-notice{
    font-size: 12px;
    text-align: center;
    line-height: 1.6em;
    max-width: 480;
    margin: auto;
    margin-bottom: 100px;
    max-width: 475px;
}
form{
    display: flex;
    flex-direction: row;
    border: solid;
    border-width: 1px;
    border-radius: 4px;
    max-width: 360px;
    margin:auto;
    padding: 10px;




    input[type="text"]{
        flex-grow: 2;
        border:none;
        &:focus{
            outline-offset: 8px;
            outline-width: 5px;
        }
    }
        
    input[type="submit"]{
        display:none;

        
    }
    svg{
        height: 12px;
        color: $linkGold;
    }
    label{
        padding-right: 10px;
        padding-top: 1px;
        &:hover{
            cursor: pointer;
        }
    }
    

}
.employee-company{
    margin-bottom: 40px;
}
.company{
    display: block;
    margin-top: 50px;

    h3{
        flex-basis: 100%;
        font-size: 20px;
        font-size: 24px;
        margin-bottom: 10px;
    }
    .company-site{
        color:$textGrey;
        font-size:14px;
    }
    .employee{
        display: flex;
        flex-basis: 100%;
        flex-wrap: wrap;
        padding: 32px 0;
        border-bottom: solid;
        border-width: 1px;
        border-color: $border;
        .list-block{
            width: 20%;
            text-align: center;
            p{
                display: inline-block;
            }
        }
        .name{
            width: 35%;
        }

        .venmo-link{
            width: 25%;
        }
        p{
            margin: 0;
            font-size: 14px;
            line-height: 17px;
            color: $textGrey;
            font-weight: 600;;
        }
        .phone{
            font-weight: 400;
        }
        a{
            color: $linkGold;
            font-size: 14px;
            line-height: 17px;
        }

        &:last-child{
            margin-bottom: 40px;
        }

    }
}
.list{
    margin-top: 68px;
    padding: 0 25px;
    &:after {
        content  : " ";
        position : relative;
        display: inline-block;
        z-index  : 1;
        bottom   : 80px;
        left     : 0;
        pointer-events   : none;
        background-image : linear-gradient(to bottom, 
                          rgba(255,255,255, 0), 
                          rgba(255,255,255, 1) 90%);
        width    : 100%;
        height   : 6em;
    }
}
.company-list{
    max-height: 500px;
    overflow: auto;
    overflow-x: hidden;

}

.company-list::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  
  .company-list::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .5);
    box-shadow: 0 0 1px rgba(255, 255, 255, .5);
  }


.partners{
    h1{
        text-align: center;
    }
    .partner-container{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        
        .partner-link{
            padding-right: 3%;
            padding-top: 12px;
            img{
            width: 90px;
            height: 90px;

            }  
                


        }
    }
}
.Resources{
    margin-top: 64px;
    margin-left: 10px;
    @media screen and (min-width: 650px) {
        margin-left:0;
        
    }
    h1{
        text-align: center;
        width: 100%;
        border-bottom: solid;
        border-width: 1px;
        padding: 30px 0px;
        border-color:$border;
    }
    a{
        color:$textGrey;
    }
    article{
        border-bottom: solid;
        border-width: 1px;
        padding: 30px 0px;
        border-color:$border;
    }
}

.grey-text{
    color: $textGrey;
}
.black-text{
    color: $textBlack;
}

.text-center{
    text-align: center;
}