footer{
    margin-top:50px;
    padding: 124px 0px;
    text-align: center;
    background-color: $footerBackground;
    color: $white;
    p{
        font-size: 12px;
    }
    img{
        width: 100px;
    }
    a{
        color: $white;
        text-decoration: none;
        vertical-align: sub;
        font-size: 12px;
    }
}