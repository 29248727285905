header{
    background-image: url(/source/img/XE1099192x.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
    .layer{
        background-color:$transparentGrey;

    }
    text-align: center;

    img{
        width: 184px;
        margin-top: 86px;
        margin-bottom: 24px;
    }
    h1{
        margin-bottom: 10px;
        font-size: 36px;
    }
    p{
        margin-top: 10px;
    }
    bold{
        font-weight: 600;
    }
    l{
        color: $linkGold;
        padding-bottom: 80px;   
    }
    .betterToledo{
        padding-bottom: 80px;

    }
}